import { setUser } from '@sentry/remix';
import {
  AuthTokens,
  fetchAuthSession,
  fetchUserAttributes,
  FetchUserAttributesOutput,
  signOut,
} from 'aws-amplify/auth';
import { traceError } from '@/utils/trace-error';

type SessionData = {
  tokens?: AuthTokens;
  userAttributes?: FetchUserAttributesOutput;
};

let sessionDataPromise: Promise<SessionData> | undefined;

let cachedUserAttributes: FetchUserAttributesOutput | undefined;

// super primitive cache invalidation
export const invalidateSession = () => {
  sessionDataPromise = undefined;
  cachedUserAttributes = undefined;

  setUser(null);
};

const fetchSession = async () => {
  const tokens = (await fetchAuthSession()).tokens;

  if (tokens && !cachedUserAttributes) {
    cachedUserAttributes = await fetchUserAttributes();

    setUser({
      id: cachedUserAttributes.sub,
      email: cachedUserAttributes.email,
      username: cachedUserAttributes.preferred_username,
    });
  }

  return { tokens, userAttributes: cachedUserAttributes };
};

export const getAuthorizedData = () =>
  (sessionDataPromise ??= fetchSession())
    .catch(async (err) => {
      traceError(new Error('Failed to fetch session data', { cause: err }));
      await signOut();

      return {
        userAttributes: undefined,
        tokens: undefined,
      } satisfies SessionData;
    })
    .finally(() => {
      sessionDataPromise = undefined;
    });
